.auto-break-inside {
  break-inside: auto;
}

.avoid-break-inside {
  break-inside: avoid-page;
}

.avoid-break-before {
  break-before: avoid;
}

.always-break-before {
  break-before: always;
  // margin-top: 25mm !important;
}

.page-break-before {
  break-before: page;
}

.page-break-after {
  break-after: page;
}

.avoid-break-after {
  break-after: avoid;
}

.always-break-after {
  break-after: always;
}

@media print {
  html {
    height: unset;
  }

  body {
    height: unset;
    min-width: unset !important;
    -webkit-print-color-adjust: exact !important;
  }

  .hide-for-pdf {
    display: none !important;
  }
}
