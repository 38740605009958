.cd-drop-shadow(@hex: @primary-1, @a: 0.25, @radius: 4px) {
  box-shadow: 0px 10px 15px -3px rgba(@hex, @a), 0px 4px 6px -2px rgba(@hex, ~'calc(@{a}/ 2)');
}

.cd-box-shadow(@hex, @a: 0.4, @radius: 4px) {
  box-shadow: rgba(@hex, @a) 0px ~'calc(@{radius}/ 2)' ~'calc(@{radius} * 2)' 0px;
}

.ch-refcard-box-shadow() {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
}

.ch-dropdown-box-shadow() {
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.09);
}

.candidatehub-box-shadow() {
  background: white;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 33px;
}

.flex-row-spacing-xs {
  display: flex;
  flex-direction: row;
  margin-left: ~'calc(-@{xs-spacing} / 2)';
  margin-right: ~'calc(-@{xs-spacing} / 2)';

  > * {
    margin-left: ~'calc(@{xs-spacing} / 2)';
    margin-right: ~'calc(@{xs-spacing} / 2)';
  }
}

.tw-flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-spacing-sm {
  display: flex;
  flex-direction: row;
  margin-left: ~'calc(-@{xs-spacing} / 2)';
  margin-right: ~'calc(-@{xs-spacing} / 2)';

  > * {
    margin-left: ~'calc(@{xs-spacing} / 2)';
    margin-right: ~'calc(@{xs-spacing} / 2)';
  }
}

.tw-flex-col-spacing-xs {
  display: flex;
  flex-direction: column;
  .spaced-vertically(@xs-spacing);
}

.tw-flex-col-spacing-md {
  display: flex;
  flex-direction: column;
  .spaced-vertically(@md-spacing);
}

.flex-row-spacing-md {
  display: flex;
  flex-direction: row;
  margin-left: -@xs-spacing;
  margin-right: -@xs-spacing;

  > * {
    margin-left: @xs-spacing;
    margin-right: @xs-spacing;
  }
}

.tw-flex-col-spacing-reg {
  display: flex;
  flex-direction: column;
  .spaced-vertically(@reg-spacing);
}

.tw-flex-col-spacing-sm {
  display: flex;
  flex-direction: column;
  .spaced-vertically(@sm-spacing);
}

.tw-flex-col-spacing-xl {
  display: flex;
  flex-direction: column;
  margin-top: -@md-spacing;
  margin-bottom: -@md-spacing;

  > * {
    margin-top: @md-spacing;
    margin-bottom: @md-spacing;
  }
}

.tw-flex-col-spacing-lg {
  display: flex;
  flex-direction: column;
  margin-top: -@sm-spacing;
  margin-bottom: -@sm-spacing;

  > * {
    margin-top: @sm-spacing;
    margin-bottom: @sm-spacing;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-spacing-lg {
  display: flex;
  flex-direction: row;
  margin-left: -@sm-spacing;
  margin-right: -@sm-spacing;

  > * {
    margin-left: @sm-spacing;
    margin-right: @sm-spacing;
  }
}

.main-container {
  max-width: 1250px;
  background-color: white;
  width: 100%;
  margin: 0 auto;
}

.dashed-border(@borderColor, @dashWidth: 4px, @borderWeight: 1px) {
  background: linear-gradient(90deg, transparent 100%, transparent 100%) top left no-repeat,
    linear-gradient(
        90deg,
        transparent ~'calc(@{dashWidth} / 2)',
        @borderColor ~'calc(@{dashWidth} / 2)',
        @borderColor 1.5 * @dashWidth,
        transparent 1.5 * @dashWidth
      )
      top center repeat-x,
    linear-gradient(90deg, transparent 50%, @borderColor 100%, transparent 50%) top right no-repeat,
    linear-gradient(0deg, transparent 50%, @borderColor 100%, transparent 50%) top left no-repeat,
    linear-gradient(
        0deg,
        transparent ~'calc(@{dashWidth} / 2)',
        @borderColor ~'calc(@{dashWidth} / 2)',
        @borderColor 1.5 * @dashWidth,
        transparent 1.5 * @dashWidth
      )
      center left repeat-y,
    linear-gradient(0deg, transparent 50%, @borderColor 100%, transparent 50%) bottom left no-repeat,
    linear-gradient(90deg, transparent 50%, @borderColor 100%, transparent 50%) bottom left
      no-repeat,
    linear-gradient(
        90deg,
        transparent ~'calc(@{dashWidth} / 2)',
        @borderColor ~'calc(@{dashWidth} / 2)',
        @borderColor 1.5 * @dashWidth,
        transparent 1.5 * @dashWidth
      )
      bottom center repeat-x,
    linear-gradient(90deg, transparent 50%, @borderColor 100%, transparent 50%) bottom right
      no-repeat,
    linear-gradient(0deg, transparent 50%, @borderColor 100%, transparent 50%) top right no-repeat,
    linear-gradient(
        0deg,
        transparent ~'calc(@{dashWidth} / 2)',
        @borderColor ~'calc(@{dashWidth} / 2)',
        @borderColor 1.5 * @dashWidth,
        transparent 1.5 * @dashWidth
      )
      center right repeat-y,
    linear-gradient(0deg, transparent 50%, @borderColor 100%, transparent 50%) bottom right
      no-repeat;
  background-size: @dashWidth @borderWeight, 2 * @dashWidth @borderWeight, @dashWidth @borderWeight,
    @borderWeight @dashWidth, @borderWeight 2 * @dashWidth, @borderWeight @dashWidth;
}

.hirer-title-container {
  .flex-row-spacing-sm();
  justify-content: space-between;
  padding-bottom: @sm-spacing;
}

.spaced-horizontally(@space) {
  margin-left: -~ 'calc(@{space} / 2)';
  margin-right: -~ 'calc(@{space} / 2)';

  > * {
    margin-left: ~'calc(@{space} / 2)';
    margin-right: ~'calc(@{space} / 2)';
  }
}

.spaced-vertically(@space) {
  margin-top: -~ 'calc(@{space} / 2)';
  margin-bottom: -~ 'calc(@{space} / 2)';

  > * {
    margin-top: ~'calc(@{space} / 2)';
    margin-bottom: ~'calc(@{space} / 2)';
  }
}

.italicize-placeholder-styles(@selector, @fontSize, @lineHeight) {
  &@{selector} {
    font-style: italic;
    line-height: @lineHeight;
    font-size: @fontSize;
  }
}

.italicize-placeholder(@fontSize: 1rem, @lineHeight: 1.5) {
  .italicize-placeholder-styles('::placeholder', @fontSize, @lineHeight);
  .italicize-placeholder-styles('::-webkit-input-placeholder', @fontSize, @lineHeight);
  .italicize-placeholder-styles(':-moz-placeholder', @fontSize, @lineHeight);
  .italicize-placeholder-styles(':-ms-input-placeholder', @fontSize, @lineHeight);
}

.toolbar(@height: 'min-height') {
  width: 100%;
  @height: 56px;

  @media (min-width: 576px) {
    @height: 64px !important;
  }

  @media (min-width: 0px) and (orientation: landscape) and (max-device-width: 599px) {
    @height: 48px;
  }
}

.minus-toolbar(@height: 'min-height') {
  width: 100%;
  @{height}: ~'calc(100% - 56px)';

  @media (min-width: 600px) {
    @{height}: ~'calc(100% - 64px)' !important;
  }

  @media (min-width: 0px) and (orientation: landscape) and (max-device-width: 599px) {
    @{height}: ~'calc(100% - 48px)';
  }
}

.flex {
  flex: 1 0 0;
  min-width: 0;
  min-height: 0;
}
