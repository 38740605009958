@import '../sl-theme.less';

.ant-input-affix-wrapper-lg {
  &.tw-bg-background {
    input {
      background: @gray-1;
    }

    &:hover,
    &:active {
      input {
        background: white;
      }
    }
  }
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: @gray-6;
}

.ant-picker-focused {
  border-color: @gray-6;
  box-shadow: none;
}

.ant-layout-header {
  height: 56px;

  @media (min-width: 600px) {
    height: 64px !important;
  }

  @media (min-width: 0px) and (orientation: landscape) and (max-device-width: 599px) {
    height: 48px;
  }
}

.ant-btn {
  &.ant-btn-text {
    color: @gray-9;
  }

  &.ant-btn-sm {
    padding: 3px 7px; // @padding-xs/2 @padding-xs
    height: 2rem;
  }
}

.ant-collapse {
  border-color: @gray-1;

  > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
  }
  > .ant-collapse-item:last-child,
  .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 12px;
  }
}

// Modal
.ant-modal-title {
  font-weight: 500;
}

.ant-modal-body {
  border: 1px solid @gray-3;
  box-shadow: 0px 32px 64px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}

// Collapse, card
.ant-card,
.ant-collapse > .ant-collapse-item {
  border-color: @gray-2;

  .ant-card-head-title {
    font-weight: bold;
  }

  &.tw-p-0 {
    > .ant-card-body,
    > .ant-card-head {
      padding: 0;
    }
  }
}

.ant-collapse-ghost.ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0;
}

.ant-tag {
  border-radius: 12px;
}

// Tooltip
.ant-tooltip-inner {
  padding: 6px 12px;
}

// Menu
.ant-menu-inline,
.ant-menu-vertical {
  border-right-color: @gray-1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

.ant-picker-input > input {
  border-radius: 4px;
}

// Skeleton
ul.ant-skeleton-paragraph {
  margin: 0;
}
