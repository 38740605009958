@font-face {
  font-family: 'Inter';
  src: url('../font/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../font/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Inter';
  src: url('../font/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('../font/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../font/NunitoSans/NunitoSans-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../font/NunitoSans/NunitoSans-Italic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../font/NunitoSans/NunitoSans-SemiBold.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../font/NunitoSans/NunitoSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../font/NunitoSans/NunitoSans-Bold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../font/NunitoSans/NunitoSans-BoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../font/NunitoSans/NunitoSans-ExtraBold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../font/NunitoSans/NunitoSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Bitter';
  src: url('../font/Bitter/Bitter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bitter-Bold';
  src: url('../font/Bitter/Bitter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  src: url('../font/Lato/Lato-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  src: url('../font/Lato/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  src: url('../font/Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ComputerModern';
  src: url('../font/ComputerModern/CMU-SerifRoman.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'ComputerModern';
  src: url('../font/ComputerModern/CMU-SerifItalic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'ComputerModern';
  src: url('../font/ComputerModern/CMU-SerifBold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'ComputerModern';
  src: url('../font/ComputerModern/CMU-SerifBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('../font/Graphik/GraphikBlack.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../font/Graphik/GraphikBlackItalic.otf') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('../font/Graphik/GraphikBold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Graphik';
  src: url('../font/Graphik/GraphikSemibold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Graphik';
  src: url('../font/Graphik/GraphikBoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('../font/Graphik/GraphikSemiboldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}
