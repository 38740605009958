@tailwind base;
@tailwind components;
@tailwind utilities;

ol,
ul {
  list-style: initial;
  margin-left: 1rem;
  padding-left: initial;
}

.tw-whitespace-break-spaces {
  white-space: break-spaces;
}

.tw-text-paragraph-100 {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
}

.tw-text-body-3.pdf {
  @media print {
    font-weight: 500 !important;
    font-size: 8px !important;
    line-height: 12px !important;
  }
}

h3.pdf {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}
