@import './config.less';
@import './styles/_print.less';
@import './styles/_antd.less';

html {
  height: 100%;
}

ul {
  &:not(.public-DraftStyleDefault-ul) {
    list-style-position: inside;
    padding-inline-start: initial;

    ul {
      list-style-type: circle;
      padding-inline-start: revert;
    }
  }
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

hr {
  height: 1px;
  border-top: 1px solid @primary-1;
  margin: 0;
}

.font08 {
  font-size: 0.8rem;
}

.dropdown-menu {
  // user nav has z-index 1300
  z-index: 1200;
}

.__react_component_tooltip {
  opacity: 1 !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.22);
  border-radius: 8px;
  max-width: 200px;
  z-index: 1201;
  white-space: break-spaces;

  &.type-light {
    color: @gray-8;
    font-weight: normal;
  }

  &.place-top:after {
    border-top-color: white !important;
  }

  &.place-bottom:after {
    border-bottom-color: white !important;
  }

  &.place-left:after {
    border-left-color: white !important;
  }

  &.place-right:after {
    border-right-color: white !important;
  }
}

.btn-wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: initial;
  align-items: stretch;

  button.button {
    max-width: unset;
  }

  &.center {
    justify-content: center;

    button {
      max-width: unset;
    }
  }

  &.btn-wrapper-lg > div {
    width: 100%;
  }

  @media (min-width: 576px) {
    justify-content: space-between;
    flex-direction: row;
  }
}

@media (max-width: 575.98px) {
  .intercom-launcher {
    display: none;
  }
}

.toolbar {
  .toolbar();
}

.flex {
  .flex();
}

.fit-content {
  width: fit-content;
}

.mt-sm-sm {
  margin-top: @sm-spacing;

  @media (min-width: 576px) {
    margin-top: 0;
  }
}

.mb-sm-sm {
  margin-bottom: @sm-spacing;

  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}

.mw-450 {
  max-width: ~'calc(@{reg-spacing} * 15)';
}

.mw-510 {
  max-width: ~'calc(@{reg-spacing} * 17)';
}

.mw-640 {
  max-width: ~'calc(@{reg-spacing} * 20)';
}

.break-word {
  word-break: break-word;
}

.background-light {
  background: @background;
}

.opacity-50 {
  opacity: 0.5;
}

.max-width-1200 {
  width: 100%;
  overflow-x: visible;

  @media (min-width: 1200px) {
    width: 1200px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.max-width-768 {
  width: 100%;

  @media (min-width: 768px) {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }
}

.max-width-992 {
  width: 100%;

  @media (min-width: 992px) {
    max-width: 992px;
    margin-left: auto;
    margin-right: auto;
  }
}

.vertically-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767.98px) {
  .full-width-on-mobile {
    width: 100% !important;
  }

  .hide-for-mobile {
    display: none !important;
    margin-left: 0;
    margin-right: 0;
  }

  .flex-on-mobile {
    flex: 1;
    min-width: 0;
    min-height: 0;
  }
}

.hide-for-desktop {
  display: none;
  margin-left: 0;
  margin-right: 0;

  @media (max-width: 576px) {
    display: block;
  }
}

.toggleable,
.cursor-pointer {
  cursor: pointer;
}

.absolutely-positions-tab-cta {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
}

@media (min-width: 576px) {
  .position-sm-absolute {
    position: absolute !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }
}

blockquote {
  border-left: 2px solid @primary-1;
  padding-left: 2px;
}

.overlay {
  height: 100%;
  width: 100%;
  position: relative;
  margin-top: 0;

  > * {
    pointer-events: none;
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
  }

  &::after {
    content: '';
    border-radius: 16px;
    top: 0;
    pointer-events: none;
    position: absolute;
    background-color: @gray-8;
    opacity: 0.02;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
}

.enabled-point-event {
  pointer-events: all !important;
}
