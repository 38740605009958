@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import './sl-theme.less';
@import './styles/_antd.less';

@font-family: 'Inter', 'Lato';

@btn-primary-shadow: none;
@primary-color: @primary;
@tabs-hover-color: @info;
@secondary-color: @secondary;
@success-color: @success;
@processing-color: @gray-1;
@warning-color: @warning;
@error-color: @error;
@text-color: @gray-9;
@text-color-secondary: @gray;
@background-color-light: @gray-1;
@link-color: @info;
@layout-header-background: white;
@layout-body-background: white;
@btn-disable-bg: transparent;
@primary-color-outline: fade(@info, @outline-fade);
@box-shadow-base: 0px 8px 8px 0px rgba(0, 0, 0, 0.078);

@menu-item-active-bg: @processing-color;
@heading-color: @text-color;
@item-hover-bg: @processing-color;
@border-color-base: @gray-3;
@border-color-split: @primary-1;
@alert-info-border-color: @border-color-base;
@process-icon-color: @btn-primary-bg;
@text-selection-bg: @link-color;
@tabs-highlight-color: @primary-6;
@tabs-hover-color: @primary-6;

@line-height-base: 1.5;
@animation-duration-base: 150;
@padding-sm: 16px;
@padding-md: 24px;
@border-radius-base: 4px;
@btn-font-size-lg: 16px;
@btn-font-size-sm: 14px;
@height-lg: 48px;
@height-sm: 32px;
@height-base: 40px;
@btn-padding-horizontal-lg: 31px;
@btn-line-height: 24px;
@font-size-base: 16px;
@font-size-sm: 12px;
@heading-1-size: 64px;
@heading-2-size: 48px;
@heading-3-size: 40px;
@heading-4-size: 32px;
@heading-5-size: 24px;
@heading-6-size: 18px;
@typography-title-font-weight: 600;
@btn-padding-horizontal-base: @padding-md - 1px;
@btn-padding-horizontal-sm: @padding-xs - 1px;
@control-padding-horizontal: 12px;

// Table
@table-header-bg: white;
@table-header-color: @gray-6;
@table-row-hover-bg: @primary-1;
@table-expanded-row-bg: @gray-1;
@table-header-sort-active-bg: @gray-1;
@table-padding-vertical-sm: 32px;
@table-padding-horizontal-sm: 16px;
@table-font-size-sm: 12px;

// Modal
@border-radius-base: 12px;
@modal-header-padding-vertical: 24px;
@modal-header-padding-horizontal: 24px;
@modal-body-padding: 24px;
@modal-footer-padding-vertical: 24px;
@modal-footer-padding-horizontal: 24px;
@modal-footer-border-width: 0px;
@modal-header-border-width: 0px;
@modal-confirm-title-font-size: 18px;

@process-icon-color: @secondary;

// Checkbox
@checkbox-border-radius: 4px;

// Card
@card-head-padding: 48px;
@card-padding-base: 48px;
@card-head-padding-sm: 24px;
@card-padding-base-sm: 24px;
@card-radius: 12px;

// Tooltip
@tooltip-bg: @gray-8;

// Dropdown
@dropdown-selected-color: @gray-7;
@dropdown-selected-bg: @gray-1;
@dropdown-vertical-padding: 12px;
@dropdown-font-size: 14px;
@dropdown-line-height: 20px;

// Skeleton
@skeleton-color: @gray-1;
